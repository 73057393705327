// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-portfolio-index-js": () => import("/opt/build/repo/src/templates/portfolio/index.js" /* webpackChunkName: "component---src-templates-portfolio-index-js" */),
  "component---src-templates-tarieven-pakketten-js": () => import("/opt/build/repo/src/templates/tarieven/pakketten.js" /* webpackChunkName: "component---src-templates-tarieven-pakketten-js" */),
  "component---src-templates-contact-index-js": () => import("/opt/build/repo/src/templates/contact/index.js" /* webpackChunkName: "component---src-templates-contact-index-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("/opt/build/repo/src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-info-fotoreportage-js": () => import("/opt/build/repo/src/pages/info/fotoreportage.js" /* webpackChunkName: "component---src-pages-info-fotoreportage-js" */),
  "component---src-pages-info-grafisch-ontwerp-js": () => import("/opt/build/repo/src/pages/info/grafisch-ontwerp.js" /* webpackChunkName: "component---src-pages-info-grafisch-ontwerp-js" */),
  "component---src-pages-tarieven-js": () => import("/opt/build/repo/src/pages/tarieven.js" /* webpackChunkName: "component---src-pages-tarieven-js" */),
  "component---src-pages-tarieven-cadeaubon-js": () => import("/opt/build/repo/src/pages/tarieven/cadeaubon.js" /* webpackChunkName: "component---src-pages-tarieven-cadeaubon-js" */)
}

